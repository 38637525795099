@use '@angular/material' as mat;
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

// .toggle-checked .toggle-inner {
//     background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADDSURBVHgBlZKBEYIwDEV/1AEYgUU8ZQM3wA1gBSdAJ1AnQdQBHIERGECNv7Vi9U5ac5ejCX1Jf1qBZ3rCHIqCywY37CRDhyHTIyq60kvEmAdsY/aP7JHgqgv2UZDT8LQr2jgIttNfZqCkj8ZYhACtkRjIH2thkoPUBLmBDl4qZbfqZ5czctyRibvQ+ut/S1+xwIUCOg4o5Z6cuSUnnImt0GDNoEBQEDYyQyl9HAIdYJbykX+9PeVXOBDl0YSahcD0rf0BFhRD9xBXy+AAAAAASUVORK5CYII=) !important;
//     background-repeat: no-repeat !important;
//     background-color: black !important;
//     background-position: center;
//     background-size: contain !important;
// }
// .toggle-checked .toggle-icon {
//     background-color: red;
// }

// slides.component.css
@import "swiper/scss";
@import "swiper/scss/autoplay";
@import "swiper/scss/keyboard";
@import "swiper/scss/pagination";
@import "swiper/scss/scrollbar";
@import "swiper/scss/zoom";
@import "swiper/scss/grid";
@import "swiper/scss/free-mode";
@import "@ionic/angular/css/ionic-swiper";

@import "./custom-theme";

// @include mat.core;
// $custom-theme-background: mat-palette($mat-background);
// $custom-theme-primary: mat-palette($mat-primary);
// $custom-theme-accent: mat-palette($mat-accent);
// $custom-theme: mat-light-theme(
//   $custom-theme-background,
//   $custom-theme-primary,
//   $custom-theme-accent
// );
// @include angular-material-theme($custom-theme);

//desktop, tablet
@media (min-width: 768px) {
  .desktop-hide {
    display: none !important;
  }
  .desktop-show {
    display: block !important;
  }
}
//mobile
@media (max-width: 767px) {
  .mobile-hide {
    display: none !important;
  }
  .mobile-show {
    display: block !important;
  }
}

//desktop, tablet
@media (min-width: 768px) {
  h1 {
    font-size: 48px;
  }
}
//mobile
@media (max-width: 767px) {
  h1 {
    font-size: 36px;
  }
  p {
    line-height: 2 !important;
  }
}

app-header {
  // position: relative;
  display: block;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
}

app-mobile-download-app {
  display: block;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

section {
  margin-top: 60px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 120px;
  // max-width: 90%;
  margin: auto;
}
section.top-section {
  padding-top: 100px !important;
  h1 {
    font-weight: 800;
  }
  //mobile
  @media (max-width: 767px) {
    h1 {
      font-size: 30px;
      font-weight: 800;
    }
  }
}
section.top-section.banner,
#our-influence, #know-more-wasp {
  .banner-div {
    // background: url(/assets/image/our-influence-banner.png) no-repeat center center / cover;
    border-radius: 32px;
    position: relative;
    p {
      color: #fff;
      position: absolute;
      bottom: 30%;
      left: 0;
      right: 0;
    }
    h1 {
      color: #fff;
      position: absolute;
      bottom: 10%;
      left: 0;
      right: 0;
      font-weight: bolder;
    }
    aspect-ratio: 1 / 0.25;
    @media (max-width: 767px) {
      aspect-ratio: 1 / 1;
    }
  }
}

//tablet
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  section {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  section.top-section {
    padding-top: 100px !important;
  }
}

//mobile
@media (max-width: 767px) {
  section {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    padding-left: 5%;
    padding-right: 5%;
  }
  section.top-section {
    padding-top: 20px !important;
  }
}

ion-button.default-btn {
  --background: transparent;
  --background-hover: var(--ion-color-primary);
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-dark);
  --color: var(--ion-color-dark);
  --color-hover: var(--ion-color-light);
  transition: all 400ms ease;
}
ion-button:hover.default-btn {
  --background: var(--ion-color-primary);
  --border-width: 0 !important;
  transform: scale(1.1);
  transition: all 400ms ease;
}

@media (prefers-color-scheme: light) {
  ion-button.default-btn {
    --background: var(--ion-color-dark);
    --background-hover: var(--ion-color-primary);
    --border-color: var(--ion-color-dark);
    --color: var(--ion-color-light);
    --color-hover: var(--ion-color-dark);
  }
}

.divider {
  height: 1px;
  background-color: #1a1a1a;
  width: 100%;
}

mat-form-field {
  --mdc-outlined-text-field-outline-color: var(--ion-color-dark) !important;
}

.swiper-slide {
  height: auto !important;
}

.gallery-div {
  //desktop, tablet
  @media (min-width: 768px) {
    max-height: 200px;
    overflow-y: scroll;
  }
  //mobile
  @media (max-width: 767px) {
    overflow-y: scroll;
  //   max-height: 70px;
  //   overflow-x: scroll;
  //   display: flex;
  //   flex-direction: row;
  //   max-width: 100%;
  //   white-space: nowrap;
  //   .img-container {
  //     margin: 0 6px 0 0 !important;
  //     min-width: 65px;
  //   }
  }
  // &::-webkit-scrollbar {
  //   display: none;
  // }
  .img-container {
    cursor: pointer;
    width: 100%;
    aspect-ratio: 1/1;
    max-width: 65px;
    border-radius: 5px;
    margin: 0 0 10px auto;
  }
}

mat-stepper.prerent-booking {
  background: transparent;
  .mat-horizontal-stepper-content {
    max-width: 500px;
  }
  .mat-step-icon {
    background-color: #333;
  }
  .mat-step-label {
    white-space: pre-line !important;
    .mat-step-text-label {
      white-space: pre-line !important;
      color: #333;
    }
  }
  .mat-step-icon-selected {
    background-color: var(--ion-color-primary);
  }
  .mat-step-label-selected {
    .mat-step-text-label {
      color: var(--ion-text-color);
    }
  }
  .mat-horizontal-stepper-header {
    pointer-events: none !important;
  }

  //mobile
  @media (max-width: 767px) {
    .mat-horizontal-content-container {
      padding: 0;
    }
    .mat-horizontal-stepper-header {
      padding: 0 8px;
    }
  }
}

.calender-container-modal {
  ion-toolbar {
    --background: transparent;
    ion-title {
      --color: #ffffff;
    }
    ion-button {
      ion-icon {
        color: #ffffff;
      }
    }
  }
}

.default-modal {
  --border-radius: 20px;
  --box-shadow: 0;
}


.supercharge-remark-modal {
  --max-height: 80%;
  --width: 260px;
  --background: #222;
  .wrapper {
    color: white !important;
  }
  img{
    height: 200px;
  }
  p{
    font-size: 0.9rem;
  }
}

.gallery-modal {
  //desktop, tablet
  // --height: 100%;
  // --width: 100%;
  @media (min-width: 768px) {
    --background: transparent;
    img{
      border-radius: 20px;
    }
  }
  --swiper-pagination-color: var(--ion-color-primary);
}

.share-modal{
  .share-icon{
    font-size: 40px;
    margin: 8px;
    cursor: pointer;
  }
}

.prerent-flow-modal{
  @media (min-width: 768px) {
    --max-width: 300px !important;
    --height: 100%;
  }
  --max-width: 250px;
  --background: transparent;
  swiper-container {
    --swiper-theme-color: var(--ion-color-primary);
    height: 100%;
    width: 100%;
  }
  img {
    height: 100%;
  }
  .shadow{
    height: 100%;
    background: rgba(27, 27, 27, 0.8);
    width: 250px;
    position: absolute;
    z-index: 99;
    top: 0;
    border-radius: 20px;
  }
}

.default-ion-calendar {
  background-color: var(--ion-bacground-color) !important;
  .switch-btn {
    color: white !important;
    font-weight: bold;
  }
  .title ion-button {
    color: white !important;
  }
  .week-title {
    li {
      font-weight: bold;
      color: white !important;
    }
  }
  button.days-btn[disabled]:not(.booked-date) p {
    color: var(--ion-color-medium) !important;
  }
  .days:not(.between):not(.endSelection)
    button.days-btn:not(.booked-date):not(.on-selected):not([disabled]):not(
      .first-date-for-monthly-booking
    )
    p {
    color: white !important;
  }
  .days .on-selected {
    background-color: var(--ion-color-primary) !important;
    p {
      color: var(--ion-color-wasp1) !important;
    }
  }
  .startSelection:before,
  .endSelection:before {
    background-color: var(--ion-color-primary) !important;
  }
  .endSelection button.days-btn {
    background-color: var(--ion-color-primary) !important;
    p {
      color: var(--ion-color-wasp1) !important;
    }
  }
  .between button.days-btn {
    background-color: var(--ion-color-primary) !important;
  }
  .between button.days-btn p {
    color: var(--ion-color-wasp1) !important;
  }
  button.days-btn:after,
  .startSelection:after,
  .endSelection:after {
    opacity: 0 !important;
  }
  .month-packer-item.this-month button {
    border: 1px solid var(--ion-color-primary) !important;
  }
  .booked-date p {
    color: red !important;
  }
  .first-date-for-monthly-booking {
    p {
      font-size: 12px !important;
      line-height: unset !important;
      color: var(--ion-color-primary) !important;
    }
    small {
      color: var(--ion-color-primary) !important;
      font-weight: 400 !important;
    }
  }
  .days-btn[disabled] {
    p {
      font-size: 0.9rem !important;
    }
  }
}

//dark mode css
body.dark {
  .divider {
    background-color: #363636 !important;
  }
  footer {
    background: #1c1d1b !important;
  }
  #services {
    .box {
      background-color: #1a1a1a !important;
    }
    .box:hover {
      background: #363636 !important;
    }
  }
  #plan {
    ion-card {
      --background: #333333 !important;
    }
    ion-card:hover {
      background: #666 !important;
    }
  }
  #goswap-app {
    ion-row {
      background: linear-gradient(
        180deg,
        #333 0%,
        rgba(51, 51, 51, 0) 100%
      ) !important;
    }
    .mobile-container {
      .background {
        background: linear-gradient(
          180deg,
          #333 0%,
          rgba(51, 51, 51, 0) 100%
        ) !important;
      }
    }
  }
  .faq-section {
    .faq-box {
      background: #363636 !important;
    }
    .faq-box.active {
      background: #666 !important;
      p {
        color: #fff !important;
      }
    }
    swiper-container {
      .detail {
        p {
          color: #ccc !important;
        }
      }
    }
  }
  #rtb-detail {
    ion-segment {
      ion-segment-button {
        --color-checked: #fff !important;
      }
      .segment-button-checked {
        border: 1px solid #fff !important;
      }
    }
    .prerent {
      background: #1a1a1a !important;
      .year {
        border: 1px solid rgba(255, 255, 255, 0.5) !important;
        color: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
  #rwp-detail, #ltr-detail {
    .option-box {
      background: #1a1a1a !important;
    }
    .option-box.active {
      color: #1a1a1a !important;
      background: var(--ion-color-primary) !important;
    }
    .option-box:hover {
      color: #1a1a1a !important;
      background: var(--ion-color-primary) !important;
    }
    .parking-info {
      background: #363636 !important;
    }
  }
  .car-section {
    .car-type {
      background: #1a1a1a !important;
      h6 {
        color: rgba(255, 255, 255, 0.5) !important;
      }
    }
    .car-type:hover {
      background: #333 !important;
    }
    .type-name {
      color: var(--ion-color-primary);
    }
    .car-list {
      .our-car,
      .car-type {
        color: #ccc !important;
      }
    }
  }
  .car-item-container {
    background: #1a1a1a !important;
    .price {
      span {
        color: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
  .car-detail-section {
    .car-list {
      .our-car,
      .car-type {
        color: #ccc !important;
      }
    }
    .year-div {
      .year {
        border: 1px solid rgba(255, 255, 255, 0.5) !important;
        color: rgba(255, 255, 255, 0.5) !important;
      }
    }
    .price-plan-container {
      .plan-container {
        background-color: #363636 !important;
        h6 {
          color: #ccc !important;
        }
        h6.primary {
          color: var(--ion-color-primary) !important;
        }
        .price-container {
          color: #999999 !important;
        }
      }
    }
  }
  #booking-detail {
    ion-segment {
      ion-segment-button {
        --color-checked: #fff !important;
      }
      .segment-button-checked {
        border: 1px solid #fff !important;
      }
    }
    .prerent-plan-option-segment {
      --background: rgba(51, 51, 51, 0.5);
      border: 1px solid #ffffff;
    }
  }

  .calender-container-modal {
    ion-toolbar {
      --background: transparent;
      ion-title {
        --color: var(--ion-bacground-color);
      }
      ion-button {
        ion-icon {
          color: var(--ion-bacground-color);
        }
      }
    }
  }

  .parking-card{
    --background: var(--ion-color-light-shade) !important;
  }
  .parking-section{
    ion-item{
      --background: #222 !important;
    }
  }
  // .prerent-now-btn{
  //   --background: unset !important;
  // }
  
  .how-to-use-goswap-app{
    background: #1a1a1a !important;
  }

  .blog-item-card:hover{
    --background: #363636 !important;
    transition: 250ms;
  }
}

